import {FC} from "react";
import {Box, Divider, Grid, Typography, useTheme} from "@mui/material";

const Footer:FC =() => {
    const {palette} = useTheme();

    return <Box sx={{maxWidth: "100%", mx:"24px", mt:{xs:"40px",sm:"106px"}}} component={"footer"}>
        <Divider variant={"fullWidth"} sx={{bgcolor: palette.primary.main, mb:"16px", mx:'auto', width: '100%', maxWidth: "1025px"}}/>
        <Grid container flexDirection={{xs: 'column-reverse', md: "row"}} alignItems={{xs: 'center', md: 'space-between'}} sx={{maxWidth: "1025px", mx:"auto", mb:{xs:"24px",sm:"66px"} }}>
            <Grid item xs={12} md={6} mt={{xs: 4, md: 0}}>
                <Typography variant={"subtitle1"} color={"primary"} mb={"8px"}>Expansja Advertising Sp. z o.o.</Typography>
                <Typography variant={"subtitle1"} color={"primary"}>ul. Kordeckiego 47, 60-144 Poznań</Typography>
            </Grid>
        </Grid>
    </Box>
}

export default Footer;
