import {FC, useState} from "react";
import arrow from "../assets/arrow.svg";
import {Collapse, Grid, styled, Typography, useTheme} from "@mui/material";

type Props = {
    title: string;
}

const ArrowIcon = styled("img")({
    height: "38px",
});

const ExpandableItem: FC<Props> = ({children, title}) => {
    const {palette} = useTheme();
    const [isExpanded, setIsExpanded] = useState(false);

    return <>
        <Grid container justifyContent={"space-between"} alignItems="center" bgcolor={palette.primary.main} py={{xs:"6px",sm:"13px"}} pl={{xs:"16px",sm:"40px"}} pr={{xs:"16px",sm:"47px"}} mb={"1px"}>
            <Typography variant={"h5"} color={"white"}>{title}</Typography>
            <ArrowIcon sx={{transform: isExpanded ? "rotate(270deg)" : "rotate(90deg)", height:{xs:"19px", sm:"38px"}}} src={arrow} alt={"arrow"} onClick={() => setIsExpanded(!isExpanded)}/>
        </Grid>
        <Collapse in={isExpanded} sx={{overflow: "hidden"}}>
            {children}
        </Collapse>
    </>
}

export default ExpandableItem;