import {FC} from "react";
import milanoLogo from "../assets/millano-logo.svg";
import stripes from "../assets/bg-stripes-narrow.svg";
import tiktok from "../assets/tiktok-logo.svg";
import yt from "../assets/yt-yellow.svg";
import inst from "../assets/instagram.svg";
import fb from "../assets/fb-mobile.svg";
import fby from "../assets/facebook-yellow.svg";
import {Box, Divider, experimental_sx as esx, Grid, styled, Theme, Typography, useTheme} from "@mui/material";
import {SxProps} from "@mui/system";
import logoHibi from '../assets/hibbi-logo.png';
import logoChoctopus from '../assets/choctopus-logo.png';
import logoPomorzanka from '../assets/pomorzanka-logo.png';

const MillanoLogo = styled("img")(esx({
    width: {xs: "58px", sm: "124px"}
}),);

const StripesBg = styled("img")(esx({
    position: "absolute",
    top: {xs: '-28%', sm: '-35px'},
    left: 0,
    zIndex: "-1",
    height: {xs: "260px", sm: "320px"},
    width: "100%",
}),);

const MillanoLink = styled("a")(({theme}) => ({
    textDecorationColor: theme.palette.primary.main,
}));

const SocialLink = styled("a")(({}) => ({
    textDecorationColor: "none",
}));

const SocialIcon = styled("img")(esx({
    height: {xs:"30px",md:"50px"},
}));

const SocialIconLink: FC<{ url: string, icon: string, sx?: SxProps<Theme> }> = ({url, icon,sx}) => {
    return <SocialLink href={url} target={"_blank"} sx={sx}>
        <SocialIcon src={icon} alt={"Social media icon"}/>
    </SocialLink>
}

const SocialLinks: FC = () => {
    const {palette} = useTheme();

    return <Box component={"section"}>
        <Box sx={{position: "relative", width: "100%"}}>
            <Box sx={{mt: {xs: "40px", sm: "64px"}, mx: "24px", height: {xs: "180px", sm: "293px"}}}>
                <Box sx={{maxWidth: "1025px", mx: "auto", height: "80%", display: "flex", flexDirection: "column", justifyContent: "center"}} mt={6}>
                    <Typography variant={"h4"} mb={{xs: '10px', sm: 0}} color={"white"}>Bądź na bieżąco!</Typography>
                    <Grid container flexDirection={{xs: 'column', sm: 'row'}} rowGap={'10px'} justifyContent={"flex-end"} alignItems={"center"} mt={{xs:"8px",sm:"30px"}}>
                        <Grid item sx={{width: {xs: '100%', sm: 'auto'}, display: 'flex', justifyContent: 'center', alignItems:'center', gap: {xs: '90px', sm: '10px', lg: '20px'}, marginLeft: {xs: '-10px', sm: '0px'}}}>
                            <SocialIconLink url={"https://www.tiktok.com/@baron_swiatczekolady"} icon={tiktok}/>
                            <SocialIconLink url={"https://www.instagram.com/baronswiatczekolady/"} icon={inst} sx={{mx: {xs:"0px", sm: '10px', lg: "30px"}}}/>
                            <SocialIconLink url={"https://www.facebook.com/BaronSwiatCzekolady"} icon={fb}/>
                            <Divider orientation={"vertical"} sx={{display: {xs: 'none', sm: 'block'}, bgcolor: 'white', mx:{xs:"auto", sm: '10px', lg:"20px"}, width: ".5px", height: "50px"}}/>
                        </Grid>
                        <Grid item sx={{width: {xs: '100%', sm: 'auto'}, display: 'flex', flexDirection: {xs: 'column', sm: 'row'}, alignItems: 'flex-end', gap: {xs: '10px', lg: '20px'}}}>
                            <Typography variant={"h5"} sx={{fontSize: {xs: '24px', sm: '30px', md: '36px'}, alignSelf: {xs: 'flex-start', sm: 'center'}}} color={"white"}>Poznaj nasze marki:</Typography>
                            <Box sx={{display: 'flex', width: {xs: '100%', sm: 'auto'}, flexDirection: {xs: 'row', sm: 'row'}, justifyContent: {xs: 'center', md: 'flex-start'}, alignItems: 'center', gap: {xs: '40px', sm: '10px', lg: '20px'}}}>
                                <a href="https://www.hibbi.pl/" target="_blank">
                                    <Box component="img" sx={{width: {xs: '60px', sm: '50px', md: '100px'}}} src={logoHibi} alt="Logo marki Hibi" />
                                </a>
                                <a href="https://www.pomorzanka.pl/" target="_blank">
                                    <Box component="img" sx={{width: {xs: '80px', sm: '90px', md: '140px'}}} src={logoPomorzanka} alt="Logo marki Pomorzanka" />
                                </a>
                                <a href="https://choctopus.pl/" target="_blank">
                                    <Box component="img" sx={{width: {xs: '60px', sm: '50px', md: '100px'}}} src={logoChoctopus} alt="Logo marki Choctopus" />
                                </a>
                            </Box>
                        </Grid>
                        {/*<Typography variant={"h6"} color={"error"} mb={"8px"}>Neonówka:</Typography>*/}
                        {/*<SocialIconLink url={"https://www.facebook.com/kabaret.neonowka"} icon={fby} sx={{ml: {xs:"auto", sm:"40px"}}}/>*/}
                        {/*<SocialIconLink url={"https://www.youtube.com/channel/UCTrnBUzPGlOVSnCXzP-PhsA"} icon={yt} sx={{ml: {xs:"auto",sm:"40px"}}}/>*/}
                    </Grid>
                </Box>
            </Box>
            <StripesBg src={stripes} alt={"stripes"}/>
        </Box>
    </Box>
}

export default SocialLinks;
