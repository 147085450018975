import {FC} from "react";
import jarkiWafel from "../assets/jarki-wafel-bg-yellow.png";
import {Box, experimental_sx as esx, styled, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import ExpandableItem from "./ExpandableItem";

const JarkiWafelImage = styled("img")(esx({
    width: "100%",
    display: "block",
    mx: "auto",
    my: {xs: "-17px", sm: "-50px",}
}),);

const BorderCell = styled(TableCell)(esx({
    borderBottom: "1px solid red",
    px: {xs: "6px", sm: "16px"},
    py: "8px",
}),);

const BorderCellWithText: typeof TableCell = ({children, sx}) => {
    return <BorderCell sx={sx}><Typography variant={"body2"} color={"primary"}>{children}</Typography></BorderCell>
}

const NutritionFacts: FC = () => {
    return <Box sx={{maxWidth: "100%", margin: "0 24px"}} component={"section"}>
        <Box sx={{maxWidth: "1025px", margin: "0 auto"}}>
            <Typography variant={"h2"} color={"primary"}>Niezły wafel!</Typography>
            <JarkiWafelImage src={jarkiWafel} alt={"Wafel Jarki"}/>
            <ExpandableItem title={"Skład"}>
                <Typography variant={"body1"} mt="16px" mb="24px" color={"primary"}>
                    Wafel z kremem kakaowym [<u>mąka pszenna</u>, tłuszcz palmowy, cukier, serwatka w proszku (<u>z
                    mleka</u>), kakao
                    w proszku o obniżonej zawartości tłuszczu 4,3 % w waflu z kremem kakaowym, <u>mleko</u> w proszku
                    odtłuszczone, olej rzepakowy, sól, emulgator: lecytyny, substancja spulchniająca: węglany sodu],
                    cukier, tłuszcz kakaowy, <u>mleko</u> w proszku pełne, <u>orzeszki arachidowe</u> 5,9 %, miazga
                    kakaowa, serwatka
                    w proszku (<u>z mleka</u>), tłuszcze roślinne (palmowy, shea) w zmiennych
                    proporcjach, <u>mleko</u> w proszku
                    odtłuszczone, pasta z <u>orzechów laskowych</u>, tłuszcz <u>mleczny</u>, emulgator: lecytyny (ze
                    słonecznika),
                    sól, pasta z <u>orzeszków arachidowych</u>, naturalny aromat, naturalny aromat orzeszka arachidowego
                    (zawiera <u>orzeszki arachidowe</u>). Czekolada mleczna: masa kakaowa minimum 31 %, masa mleczna
                    minimum 20
                    %. Może zawierać inne <u>orzechy, jaja, soję</u> i inne zboża zawierające <u>gluten</u>.
                </Typography>
            </ExpandableItem>
            <ExpandableItem title={"Wartości odżywcze"}>
                <Table sx={{maxWidth: "500px", border: "1px solid red", mt: "16px", mb: "32px"}}>
                    <TableBody>
                        <TableRow>
                            <BorderCellWithText>Wartość&nbsp;odżywcza</BorderCellWithText>
                            <BorderCellWithText sx={{textAlign: "center"}}>w&nbsp;100&nbsp;g</BorderCellWithText>
                            <BorderCellWithText sx={{textAlign: "center"}}>w&nbsp;35g&nbsp;/ 1&nbsp;baton</BorderCellWithText>
                        </TableRow>
                        <TableRow>
                            <BorderCellWithText>Wartość&nbsp;energetyczna</BorderCellWithText>
                            <BorderCellWithText sx={{textAlign: "center"}}>2229&nbsp;kJ/ 535&nbsp;kcal</BorderCellWithText>
                            <BorderCellWithText sx={{textAlign: "center"}}>780&nbsp;kJ/
                                187&nbsp;kcal</BorderCellWithText>
                        </TableRow>
                        <TableRow>
                            <BorderCellWithText sx={{borderBottom: "transparent"}}>Tłuszcz&nbsp;g</BorderCellWithText>
                            <BorderCellWithText
                                sx={{borderBottom: "transparent", textAlign: "center"}}>33</BorderCellWithText>
                            <BorderCellWithText
                                sx={{borderBottom: "transparent", textAlign: "center"}}>12</BorderCellWithText>
                        </TableRow>
                        <TableRow>
                            <BorderCellWithText
                                sx={{pt: 0, verticalAlign: "top"}}>-&nbsp;w&nbsp;tym&nbsp;kwasy&nbsp;tłuszczowe
                                nasycone&nbsp;g</BorderCellWithText>
                            <BorderCellWithText
                                sx={{pt: 0, verticalAlign: "top", textAlign: "center"}}>18</BorderCellWithText>
                            <BorderCellWithText
                                sx={{pt: 0, verticalAlign: "top", textAlign: "center"}}>6,4</BorderCellWithText>
                        </TableRow>
                        <TableRow>
                            <BorderCellWithText
                                sx={{borderBottom: "transparent"}}>Węglowodany&nbsp;g</BorderCellWithText>
                            <BorderCellWithText
                                sx={{borderBottom: "transparent", textAlign: "center"}}>51</BorderCellWithText>
                            <BorderCellWithText
                                sx={{borderBottom: "transparent", textAlign: "center"}}>18</BorderCellWithText>
                        </TableRow>
                        <TableRow>
                            <BorderCellWithText sx={{
                                pt: 0,
                                verticalAlign: "top"
                            }}>-&nbsp;w&nbsp;tym&nbsp;cukry&nbsp;g</BorderCellWithText>
                            <BorderCellWithText
                                sx={{pt: 0, verticalAlign: "top", textAlign: "center"}}>43</BorderCellWithText>
                            <BorderCellWithText
                                sx={{pt: 0, verticalAlign: "top", textAlign: "center"}}>15</BorderCellWithText>
                        </TableRow>
                        <TableRow>
                            <BorderCellWithText>Białko&nbsp;g</BorderCellWithText>
                            <BorderCellWithText sx={{textAlign: "center"}}>7,3</BorderCellWithText>
                            <BorderCellWithText sx={{textAlign: "center"}}>2,6</BorderCellWithText>
                        </TableRow>
                        <TableRow>
                            <BorderCellWithText>Sól&nbsp;g</BorderCellWithText>
                            <BorderCellWithText sx={{textAlign: "center"}}>0,38</BorderCellWithText>
                            <BorderCellWithText sx={{textAlign: "center"}}>0,13</BorderCellWithText>
                        </TableRow>
                    </TableBody>
                </Table>
                <Typography mt="24px" variant={"body1"} color={"primary"}><sup>*</sup> Referencyjna Wartość Spożycia dla
                    przeciętnej osoby dorosłej (8400&nbsp;kJ/2000&nbsp;kcal).</Typography>
                <Typography variant={"body1"} color={"primary"}>Opakowanie 35&nbsp;g zawiera 1 sugerowaną porcję
                    produktu.</Typography>
            </ExpandableItem>
        </Box>
    </Box>
}

export default NutritionFacts;
