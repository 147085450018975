import {Box, Grid, Typography} from '@mui/material';
import './SmallSweat.css'
import wafer from '../assets/wavesIntroduce.png'
import waves from '../assets/wavesGold.svg';
import label from '../assets/labelGold.svg';
import PrizeBanner from './PrizeBanner';

function SmallSweat() {
    return (
        <Box sx={{width: "100%", position: "relative", mt: {xs: "80px", sm: '50px', lg: "120px"}}}>
            {/* Background images - red wives and text */}
            <Box component='img' src={waves} alt='Baton Jarki' className='bg'/>
            <Grid container justifyContent='center' sx={{maxWidth: "1025px", margin: "auto", position: 'relative'}}>
                <PrizeBanner/>
                {/* Upper section*/}
                <Grid item xs={12} mx={{xs: '24px', lg: 0}}>

                    <Typography variant="h3" mt={{xs: 2.5, sm: 12, md: 12}}
                                sx={{
                                    zIndex: '3',
                                    color: 'white',
                                    position: 'relative'
                                }}
                    ><Box component={'span'} sx={{fontSize: {xs: '36px', sm: '54px'}}}>Mały,</Box> ale słodki <br/>baton do schrupania!</Typography>
                </Grid>
                {/* Bottom section */}
                <Grid item xs={12} mt={{xs: -13, sm: -9, md: -5}} mx={{xs: '24px', lg: 0}}
                      sx={{
                          display: 'flex',
                          justifyContent: 'space-between'
                      }}
                >
                    <Box sx={{display: {xs: 'none', sm: 'block'}}}>
                        {/* Dark yellow box - and white text */}
                        <Box component='img' src={label} alt='Nowości batonów Jarki' sx={{zIndex: '1'}}
                             width={{xs: '58px', sm: '140px'}} mt={{xs: 15, sm: 2, md: 12}}/>
                        <Typography variant='h4' className='subtitle-new' mt={{xs: -3.5, sm: -8}}
                                    ml={{xs: .7, sm: 4.5}}>Nowość</Typography>
                    </Box>
                    <Box
                        sx={{
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            zIndex: '3',
                            flex: 1,
                            padding: {xs: '180px 0', sm: 0}
                        }}
                        mr={{xs: 4, sm: 6, lg: 4, xl: 0}}
                        mt={{xs: 10, md: -3}}
                        alignItems={{xs: 'center', md: 'end'}}
                    >
                        {/* Cookie image - and text */}
                        <Box component='img' src={wafer} alt='Baton Jarki'
                             width={{xs: '264px', sm: '370px', md: '75%', lg: '680px', xl: '880px'}} ml={{xs: 0, sm: 0, md: -8}} mt={{xs: -20, sm: -10}}/>
                        <Typography variant="h3" mt={{xs: -4, sm: -6, md: -10, lg: -6}} ml={{xs: -4, sm: -6, md: -6}}
                                    width={{xs: '55%', sm: '51%', md: '49%'}} className='subtitle--bigger' sx={{zIndex: 2, rotate: {xs: '5deg', sm: '15deg'}, color: '#52150C', fontSize: '32px', position: 'absolute', top: {xs: '50%', xl: '70%'}, left: {xs: '5.5%', sm: '', md: '3%'}}}><Box component={"span"} sx={{fontSize: {xs: '24px', sm: '46px'}}}>Wafelek</Box>
                          <Box component={"span"} sx={{fontSize: {xs: '20px', sm: '40px'}}}> w czekoladzie deserowej z nadzieniem arachidowym</Box></Typography>
                        <Typography variant="h3" mt={{xs: -4, sm: -6, md: -6}} mr={{xs: -4, sm: 6, md: 0}}
                                  width={{xs: '55%', sm: '51%', md: '49%'}} className='subtitle--big' sx={{zIndex: 2, rotate: {xs: '-5deg', sm: '-10deg'}, color: '#52150C', fontSize: '40px', position: 'absolute', top: '47%', left: {xs: '55%', sm: '55%', md: '57%'}}}><Box component={"span"} sx={{fontSize: {xs: '28px', sm: '38px'}}}>Wafelek</Box>
                          <Box component={"span"} sx={{fontSize: {xs: '18px', sm: '32px'}}}> w czekoladzie mlecznej z nadzieniem arachidowym</Box></Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default SmallSweat;
