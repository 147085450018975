import {Box, Grid, Theme, Typography, useMediaQuery} from "@mui/material";
import ReactPlayer from 'react-player';
import jarki from '../assets/jarki_wafel_9.png';
import frameMobile from '../assets/BG.svg';
import frameDesktop from '../assets/bg-stripes.svg';
// import arrow from '../assets/arrow.svg'
import './IntroduceJarki.css'
import {FC} from "react";

const IntroduceJarki: FC = () => {
    const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))

    const videos = {
        first: {
          url: 'https://www.youtube.com/watch?v=5p7pvLxBaEc',
          desc: 'Przedstawiamy Jarki',
        },
        second: {
            url: 'https://www.youtube.com/watch?v=Gjx5ZVZTpyQ&t=960s',
            desc: 'Neonówka skecz wigilijny 2020 - Jarki pojawiają się po raz pierwszy',
        },
        third: {
            url: 'https://www.youtube.com/watch?v=ZLsKjQSIONY',
            desc: 'Jarki - spot kinowy',
        },
        fourth: {
          url: 'https://www.youtube.com/watch?v=coCQIAeaiNA',
          desc: 'Jarki - spot 2',
        },
        fifth: {
          url: 'https://www.youtube.com/watch?v=sElhLcagn0k&t=1471s',
          desc: 'Neo-Nówka - "Wigilia 2022"',
        },
        sixth: {
          url: 'https://www.youtube.com/watch?v=UjzXwURHwic',
          desc: `Pol'and'Rock 2022: Jakub Żulczyk i Neo-Nówka na ASP`,
        }
    }

    const getBg = () => {
        if (matches) {
            return frameDesktop;
        }
        return frameMobile;
    }

    return (
        <Grid container sx={{maxWidth: "1025px", margin: "auto", mb: {xs: "50px", md: "100px", lg: "200px"}}}>
            <Grid item xs={12} mt={{xs: 0, md: 2}}>
                {/* Background red waves */}
                <Box component='img' src={getBg()} alt='Baton Jarki' className='big-waves'/>
                {/* Introduce Jarki */}
                <Grid item
                      sx={{
                          zIndex: 3,
                          position: 'relative',
                          display: 'flex',
                          alignItems: "center",
                      }}
                      mt={{xs: "90px", md: "120px"}} mx={{xs: '24px', lg: 0}}
                >
                    <Typography variant='h4' color={'white'}>Przedstawiamy</Typography>
                    <Box component='img' src={jarki} alt='Baton Jarki' className='jarki__image'/>
                </Grid>
                {/* YouTube videos box */}
                <Grid item
                      sx={{
                          zIndex: 3,
                          position: 'relative',
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexWrap: 'wrap'
                      }}
                      alignItems={{xs: 'center', lg: 'stretch'}}
                      flexDirection={{xs: 'column', lg: 'row'}} mt={5}
                >
                    <Grid item>
                      <Grid item className='player'>
                        <ReactPlayer
                          width={'100%'}
                          height={'100%'}
                          controls={true}
                          url={videos.first.url}
                        />
                      </Grid>
                      <Typography variant='h6' className="videos__description" mb={{xs: '24px', lg: 0}} mt={'16px'}
                                  mx={{xs: '24px', sm: 0}}>{videos.first.desc}</Typography>
                    </Grid>
                    <Grid item>
                        <Grid item className='player'>
                            <ReactPlayer
                                width={'100%'}
                                height={'100%'}
                                controls={true}
                                url={videos.second.url}
                            />
                        </Grid>
                        <Typography variant='h6' className="videos__description" mb={{xs: '24px', lg: 0}} mt={'16px'}
                                    mx={{xs: '24px', sm: 0}}>{videos.second.desc}</Typography>
                    </Grid>
                    <Grid item>
                        <Grid item className='player' mt={{xs: 0, lg: 5 }}>
                            <ReactPlayer
                                width={'100%'}
                                height={'100%'}
                                controls={true}
                                url={videos.third.url}
                            />
                        </Grid>
                        <Typography variant='h6' className="videos__description" mb={{xs: 6, lg: 0}} mt={'16px'}
                                    mx={{xs: '24px', sm: 0}}>{videos.third.desc}</Typography>
                    </Grid>
                    <Grid item>
                      <Grid item className='player' mt={{xs: 0, lg: 5 }}>
                        <ReactPlayer
                          width={'100%'}
                          height={'100%'}
                          controls={true}
                          url={videos.fourth.url}
                        />
                      </Grid>
                      <Typography variant='h6' className="videos__description" mb={{xs: 6, lg: 0}} mt={'16px'}
                                  mx={{xs: '24px', sm: 0}}>{videos.fourth.desc}</Typography>
                    </Grid>
                  <Grid item>
                    <Grid item className='player' mt={{xs: 0, lg: 5 }}>
                      <ReactPlayer
                        width={'100%'}
                        height={'100%'}
                        controls={true}
                        url={videos.fifth.url}
                      />
                    </Grid>
                    <Typography variant='h6' className="videos__description" mb={{xs: 6, lg: 0}} mt={'16px'}
                                mx={{xs: '24px', sm: 0}}>{videos.fifth.desc}</Typography>
                  </Grid>
                  <Grid item>
                    <Grid item className='player' mt={{xs: 0, lg: 5 }}>
                      <ReactPlayer
                        width={'100%'}
                        height={'100%'}
                        controls={true}
                        url={videos.sixth.url}
                      />
                    </Grid>
                    <Typography variant='h6' className="videos__description" mb={{xs: 6, lg: 0}} mt={'16px'}
                                mx={{xs: '24px', sm: 0}}>{videos.sixth.desc}</Typography>
                  </Grid>
                </Grid>
                {/* <Grid item sx={{zIndex: 3, position: 'relative', justifyContent: 'end'}} display={{ xs: 'none', md: 'flex'}} mt={2}>*/}
                {/*    <Box component='img' src={arrow} alt='Strzałka' className='arrow' />*/}
                {/*</Grid>*/}
            </Grid>
        </Grid>
    )
};

export default IntroduceJarki;
