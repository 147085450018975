import {Box, styled, useMediaQuery, useTheme} from "@mui/material";
import allegro from "../assets/Allegro.png";
import shopee from "../assets/Shopee.png";
import header from "../assets/Baron_sklep.png";

const TitleImage = styled("img")({
    width: "100%",
})

const StyledLink = styled("a")({
    width: "100%",
    textAlign: "center",
    lineHeight: "20px",
    fontSize: "10px",
})

type Props = {
    link: string;
    iconUrl: string;
    height: number;
}

const LinkImage = ({link, iconUrl, height}: Props) => {
    const StyledImg = styled("img")({
        maxHeight: height + "px",
        maxWidth: "100%",
    })
    return <StyledLink href={link} target={"_blank"}><StyledImg src={iconUrl} alt={"icon"}/></StyledLink>
}

const Shop = () => {
    const theme = useTheme();
    const lg = useMediaQuery(theme.breakpoints.up('lg'));
    const md = useMediaQuery(theme.breakpoints.up('md'));

    const getFactor = () => {
        if(lg) return 1;
        if(md) return 0.8;
        else return 0.6;
    }
    const factor = getFactor();

    const shopSettings = {
        iconWidth: 150,
        color: "#000000",
        iconUrl: header,
        links: [
            {
                url: "https://allegro.pl/uzytkownik/Millano-official?string=Baron",
                iconUrl: allegro,
                iconHeight: 100
            },
            // {
            //     url: "https://shopee.pl/millano.official",
            //     iconUrl: shopee,
            //     iconHeight: 100
            // }
        ]

    }

    const boxPadding = shopSettings.links.length > 1 ?
        20 * factor + "px " + 20 * factor + "px" :
        40 * factor + "px " + 20 * factor + "px";

    return <>{shopSettings && <Box sx={{
        position: "fixed",
        bottom: "50px",
        width: shopSettings.iconWidth * factor + "px",
        right: 0,
        zIndex: 1000000,
        backgroundColor: shopSettings.color,
        boxShadow: "-10px 10px 30px #444444"
    }}>
        <TitleImage src={shopSettings.iconUrl} alt={"Odwiedź nasz sklep"}/>
        <Box sx={{
            backgroundColor: "white",
            marginLeft: "5px",
            marginBottom: "5px",
            padding: boxPadding,
            maxWidth: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            rowGap: 20 * factor + "px",
            alignContent: "center"
        }}>
            {shopSettings.links.map(link => <LinkImage link={link.url} iconUrl={link.iconUrl} height={link.iconHeight * factor}/>)}
        </Box>
    </Box>}</>
}

export default Shop;