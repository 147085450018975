import {FC} from "react";
import jarkiWafel from "../assets/jarki-wafel-gold.png";
import milanoLogo from "../assets/millano-logo.svg";
import {
    Box,
    experimental_sx as esx,
    Grid,
    styled,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from "@mui/material";
import ExpandableItem from "./ExpandableItem";

const JarkiWafelImage = styled("img")(esx({
    width: "100%",
    display: "block",
    // mx: "auto",
    // my: {xs: "-17px", sm: "-50px",}
    m: {xs: '30px auto 10px auto', sm: '40px auto -30px auto'}
}),);

const MillanoLogo = styled("img")(esx({
    width: {xs: "58px", sm: "124px"}
}),);

const MillanoLink = styled("a")(({theme}) => ({
    textDecorationColor: theme.palette.primary.main,
}));

const BorderCell = styled(TableCell)(esx({
    borderBottom: "1px solid red",
    px: {xs: "6px", sm: "16px"},
    py: "8px",
}),);

const BorderCellWithText: typeof TableCell = ({children, sx}) => {
    return <BorderCell sx={sx}><Typography variant={"body2"} color={"primary"}>{children}</Typography></BorderCell>
}

const NutritionFactsGold: FC = () => {
    return (

        <Box sx={{maxWidth: "100%", margin: "100px 24px 120px 24px"}} component={"section"}>
            
            <Box sx={{maxWidth: "1025px", margin: "0 auto"}}>
                <Typography variant={"h2"} color={"primary"}>Niezły wafel!</Typography>
                <JarkiWafelImage src={jarkiWafel} alt={"Wafel Jarki"}/>
                <ExpandableItem title={"Skład"}>
                    <Typography variant={"body1"} mt="16px" mb="24px" color={"primary"}>
                        Wafel z kremem kakaowym (31 %) oraz nadzienie (17 %) arachidowe w czekoladzie (52 %).
                        Składniki: wafel z kremem kakaowym [mąka <u>pszenna</u>, tłuszcz palmowy, cukier, serwatka w
                        proszku (z <u>mleka</u>), kakao w proszku o obniżonej zawartości tłuszczu 4,3 % w waflu z
                        kremem kakaowym, <u>mleko</u> w proszku odtłuszczone, olej rzepakowy, sól, emulgator:
                        lecytyny; substancja spulchniająca: węglany sodu], cukier, miazga kakaowa, tłuszcz kakaowy,&nbsp;
                        <u>orzeszki arachidowe</u> 5,9 %, tłuszcze roślinne (palmowy, shea) w zmiennych proporcjach,
                        serwatka w proszku (z <u>mleka</u>), <u>mleko</u> w proszku odtłuszczone, emulgator: lecytyny (z <u>soi</u>),
                        sól, naturalny aromat orzeszka arachidowego (zawiera <u>orzeszki arachidowe</u>), tłuszcz&nbsp;
                        <u>mleczny</u>, pasta orzechowa (<u>orzechy laskowe, orzeszki arachidowe</u>), ekstrakt z wanilii.
                        Czekolada: masa kakaowa minimum 50 %. Może zawierać inne <u>orzechy, jaja</u> i inne zboża
                        zawierające <u>gluten</u>.
                    </Typography>
                </ExpandableItem>
                <ExpandableItem title={"Wartości odżywcze"}>
                    <Table sx={{maxWidth: "500px", border: "1px solid red", mt: "16px", mb: "32px"}}>
                        <TableBody>
                            <TableRow>
                                <BorderCellWithText>Wartość&nbsp;odżywcza</BorderCellWithText>
                                <BorderCellWithText sx={{textAlign: "center"}}>w&nbsp;100&nbsp;g</BorderCellWithText>
                                <BorderCellWithText sx={{textAlign: "center"}}>w&nbsp;35g&nbsp;/ 1&nbsp;baton</BorderCellWithText>
                                <BorderCellWithText sx={{textAlign: "center"}}>Porcja&nbsp;pokrywa: &nbsp;%&nbsp;*</BorderCellWithText>
                            </TableRow>
                            <TableRow>
                                <BorderCellWithText>Wartość&nbsp;energetyczna</BorderCellWithText>
                                <BorderCellWithText sx={{textAlign: "center"}}>2212&nbsp;kJ/ 531&nbsp;kcal</BorderCellWithText>
                                <BorderCellWithText sx={{textAlign: "center"}}>774&nbsp;kJ/
                                    186&nbsp;kcal</BorderCellWithText>
                                <BorderCellWithText sx={{textAlign: "center"}}>9</BorderCellWithText>
                            </TableRow>
                            <TableRow>
                                <BorderCellWithText sx={{borderBottom: "transparent"}}>Tłuszcz&nbsp;g</BorderCellWithText>
                                <BorderCellWithText
                                    sx={{borderBottom: "transparent", textAlign: "center"}}>33</BorderCellWithText>
                                <BorderCellWithText
                                    sx={{borderBottom: "transparent", textAlign: "center"}}>12</BorderCellWithText>
                                <BorderCellWithText
                                    sx={{borderBottom: "transparent", textAlign: "center"}}>16</BorderCellWithText>
                            </TableRow>
                            <TableRow>
                                <BorderCellWithText
                                    sx={{pt: 0, verticalAlign: "top"}}>-&nbsp;w&nbsp;tym&nbsp;kwasy&nbsp;tłuszczowe
                                    nasycone&nbsp;g</BorderCellWithText>
                                <BorderCellWithText
                                    sx={{pt: 0, verticalAlign: "top", textAlign: "center"}}>18</BorderCellWithText>
                                <BorderCellWithText
                                    sx={{pt: 0, verticalAlign: "top", textAlign: "center"}}>6,4</BorderCellWithText>
                                <BorderCellWithText
                                    sx={{pt: 0, verticalAlign: "top", textAlign: "center"}}>32</BorderCellWithText>
                            </TableRow>
                            <TableRow>
                                <BorderCellWithText
                                    sx={{borderBottom: "transparent"}}>Węglowodany&nbsp;g</BorderCellWithText>
                                <BorderCellWithText
                                    sx={{borderBottom: "transparent", textAlign: "center"}}>50</BorderCellWithText>
                                <BorderCellWithText
                                    sx={{borderBottom: "transparent", textAlign: "center"}}>17</BorderCellWithText>
                                <BorderCellWithText
                                    sx={{borderBottom: "transparent", textAlign: "center"}}>7</BorderCellWithText>
                            </TableRow>
                            <TableRow>
                                <BorderCellWithText sx={{
                                    pt: 0,
                                    verticalAlign: "top"
                                }}>-&nbsp;w&nbsp;tym&nbsp;cukry&nbsp;g</BorderCellWithText>
                                <BorderCellWithText
                                    sx={{pt: 0, verticalAlign: "top", textAlign: "center"}}>41</BorderCellWithText>
                                <BorderCellWithText
                                    sx={{pt: 0, verticalAlign: "top", textAlign: "center"}}>14</BorderCellWithText>
                                <BorderCellWithText
                                    sx={{pt: 0, verticalAlign: "top", textAlign: "center"}}>16</BorderCellWithText>
                            </TableRow>
                            <TableRow>
                                <BorderCellWithText>Białko&nbsp;g</BorderCellWithText>
                                <BorderCellWithText sx={{textAlign: "center"}}>6,6</BorderCellWithText>
                                <BorderCellWithText sx={{textAlign: "center"}}>2,3</BorderCellWithText>
                                <BorderCellWithText sx={{textAlign: "center"}}>5</BorderCellWithText>
                            </TableRow>
                            <TableRow>
                                <BorderCellWithText>Sól&nbsp;g</BorderCellWithText>
                                <BorderCellWithText sx={{textAlign: "center"}}>0,32</BorderCellWithText>
                                <BorderCellWithText sx={{textAlign: "center"}}>0,11</BorderCellWithText>
                                <BorderCellWithText sx={{textAlign: "center"}}>2</BorderCellWithText>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Typography mt="24px" variant={"body1"} color={"primary"}><sup>*</sup> Referencyjna Wartość Spożycia dla
                        przeciętnej osoby dorosłej (8400&nbsp;kJ/2000&nbsp;kcal).</Typography>
                    <Typography variant={"body1"} color={"primary"}>Opakowanie 35&nbsp;g zawiera 1 sugerowaną porcję
                        produktu.</Typography>
                </ExpandableItem>
            </Box>
            <Box sx={{maxWidth: "100%", margin: "0 24px 104px 24px"}}>
                <Grid container flexWrap={"nowrap"} sx={{maxWidth: "1025px", mt: {xs: "40px", sm: "64px"}, mx: "auto"}}>
                    <Grid item flexBasis={1} mr={{xs: "8px", sm: "16px"}}>
                        <MillanoLogo src={milanoLogo} alt={"Milano logo"}/>
                    </Grid>
                    <Grid container flexGrow={1} item flexDirection={"column"} justifyContent={"space-around"}
                          py={{sm: "20px"}}>
                        <Typography variant={"subtitle1"} color={"primary"}>Millano sp. z o.o. S.K.A.</Typography>
                        <Typography variant={"subtitle1"} color={"primary"}>ul. Wysogotowska 15, 62-081
                            Przeźmierowo</Typography>
                        <MillanoLink href={"https://www.millano.com.pl"} target={"_blank"}><Typography variant={"subtitle2"}
                                                                                                       color={"primary"}>www.millano.com.pl</Typography></MillanoLink>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default NutritionFactsGold;
