import {Box, Typography, useMediaQuery} from "@mui/material"
import prize from '../assets/nagroda.png'

const PrizeBanner = () => {
    const lg = useMediaQuery('(max-width:1200px)')

    const show = () => {
        const currentDate = new Date();
        const targetDateTime = new Date(2023, 6, 24, 8, 0, 0, 0);
        return currentDate.getTime() > targetDateTime.getTime();
    }

    return <>
        {
            show() && <Box
                sx={{
                    position: 'absolute',
                    top: {xs: '-10%',sm: '-5%', md: '-10%', lg: '-20%'},
                    left: {xs: '10px', lg: lg ? 0 : '-5vw'},
                    zIndex: 100,
                    backgroundColor: 'grey',
                    display: 'flex',
                    alignItems: 'start',
                    justifyContent: 'start',
                    rotate: '-8deg'
                }}
            >
                <Box
                    sx={{
                        mr: '10px'
                    }}
                >
                    <Typography
                        variant="h3"
                        sx={{
                            color: '#52150C',
                            fontSize: {xs: '24px', sm: '30px', lg: '36px'}
                        }}
                    >
                        Hit Handlu - 2023
                    </Typography>
                    <Typography
                        variant="h3"
                        sx={{
                            color: '#52150C',
                            fontSize: {xs: '16px', sm: '24px', lg: '30px'},
                            textAlign: 'end'
                        }}
                    >
                        Jarki godne nagrody
                    </Typography>
                </Box>
                <Box
                    sx={{
                        maxWidth: '253px',
                        maxHeight: '253px',
                        height: {xs: '15vw', lg: '18vw'},
                        width: {xs: '15vw', lg: '18vw'},
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                        }}
                        component={'img'}
                        src={prize}
                    />
                </Box>

            </Box>
        }
    </>
}

export default PrizeBanner;