import React from 'react';
import MainLayout from "./layout/MainLayout";
import {createTheme, ThemeProvider} from "@mui/material";
import NutritionFacts from "./components/NutritionFacts";
import SocialLinks from "./components/SocialLinks";
import SmallSweat from './components/SmallSweat';
import IntroduceJarki from './components/IntroduceJarki';
import Shop from "./components/Shop";
import NutritionFactsGold from './components/NutritionFactsGold';

const theme = createTheme({
    palette: {
        primary: {
            main: "#52150C",
            light: '#DB0B23'
        },
        secondary: {
            main: "#E30613",
        },
        error: {
            main: "#F0BC04",
        }
    },
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            'sans-serif',
            'Sketchnote Square',
            'Franklin Gothic',
            'Museo Sans',
        ].join(','),
    },
});
theme.breakpoints.values.lg = 1073;

theme.typography.h1 = {
    fontFamily: ['Franklin Gothic'].join(','),
    fontSize: 40,
    [theme.breakpoints.up('sm')]: {
        fontSize: 60,
        fontFamily: ['Franklin Gothic'].join(','),
    }
}

theme.typography.h2 = {
    fontFamily: ['Sketchnote Square'].join(','),
    fontSize: 26,
    fontWeight: 400,
    [theme.breakpoints.up('sm')]: {
        fontSize: 48,
        fontFamily: ['Sketchnote Square'].join(','),
        fontWeight: 400,
    }
}

theme.typography.h4 = {
    fontFamily: ['Franklin Gothic'].join(','),
    fontSize: 35,
    [theme.breakpoints.up('sm')]: {
        fontSize: 54,
        fontFamily: ['Franklin Gothic'].join(','),
    }
}

theme.typography.h5 = {
    fontFamily: ['Franklin Gothic'].join(','),
    fontSize: 24,
    [theme.breakpoints.up('sm')]: {
        fontSize: 36,
        fontFamily: ['Franklin Gothic'].join(','),

    }
}

theme.typography.h6 = {
    fontFamily: ['Franklin Gothic'].join(','),
    fontSize: 18,
    [theme.breakpoints.up('sm')]: {
        fontSize: 36,
        fontFamily: ['Franklin Gothic'].join(','),
    }
}
theme.typography.h3 = {
    fontFamily: ['Sketchnote Square'].join(','),
    fontSize: 20,
    fontWeight: 400,
    [theme.breakpoints.up('sm')]: {
        fontSize: 36,
        fontFamily: ['Sketchnote Square'].join(','),
        fontWeight: 400,
    },
    [theme.breakpoints.up('md')]: {
        fontSize: 48,
        fontFamily: ['Sketchnote Square'].join(','),
        fontWeight: 400,
    }
}

// Nutrition description
theme.typography.body1 = {
    fontFamily: ['Franklin Gothic'].join(','),
    fontSize: 18,
    fontWeight: 600,
    [theme.breakpoints.up('sm')]: {
        fontFamily: ['Franklin Gothic'].join(','),
        fontSize: 24,
        fontWeight: 600,
    }
}

// Nutrition table
theme.typography.body2 = {
    fontFamily: ['Franklin Gothic'].join(','),
    fontSize: 14,
    fontWeight: 600,
    [theme.breakpoints.up('sm')]: {
        fontFamily: ['Franklin Gothic'].join(','),
        fontSize: 24,
        fontWeight: 600,
    }
}

theme.typography.subtitle1 = {
    fontFamily: ['Museo Sans'].join(','),
    fontSize: 12,
    fontWeight: 300,
    [theme.breakpoints.up('sm')]: {
        fontFamily: ['Museo Sans'].join(','),
        fontSize: 18,
        fontWeight: 300,
    }
}

theme.typography.subtitle2 = {
    fontFamily: ['Museo Sans 700'].join(','),
    fontSize: 12,
    textDecorationColor: theme.palette.primary.main,
    [theme.breakpoints.up('sm')]: {
        fontFamily: ['Museo Sans 700'].join(','),
        fontSize: 18,
        textDecorationColor: theme.palette.primary.main,
    }
}

function App() {
    return (
        <ThemeProvider theme={theme}>
            <MainLayout>
                <Shop/>
                <SmallSweat/>
                <IntroduceJarki/>
                <NutritionFacts/>
                <NutritionFactsGold/>
                <SocialLinks/>
            </MainLayout>
        </ThemeProvider>
    );
}

export default App;
