import {FC} from "react";
// import neonowka from "../assets/Slider.png";
// import neonowkaGold from '../assets/Slider_red.png';
import neonowka from "../assets/Slider_red.jpg";
import neonowkaGold from '../assets/Slider.png';
// import cityBg from "../assets/city-bg.png";
import baronLogo from "../assets/baron.svg";
// import jarkiWafel from "../assets/jarki-wafel.png";
// import jarkiWafelGold from "../assets/jarkiGold.png";
import waves from "../assets/bg-waves-slice.svg";
// import rulesText from "../assets/rules-text.svg";
import buttons from '../assets/buttons.png';
import neoText from "../assets/neo-text.svg";
import {Box, experimental_sx as esx, Grid, styled} from "@mui/material";
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

const NeoImage = styled("img")(esx({
    maxWidth: {xs: "130%", sm: "100%"},
    marginTop: {xs: "20px", sm: 0},
}),);

const RulesTextImage = styled("img")(esx({
    position: "absolute",
    width: {xs: "60%", sm: '30%'},
    maxWidth: {xs: "212px", sm: '329px'},
    bottom: {xs: "-40%", sm: "-25%"},
    left: {xs: "35%", sm: "50%"},
}),);

const NeoTextImage = styled("img")(esx({
    position: "absolute",
    width: "25%",
    maxWidth: "304px",
    top: {xs: "25px", sm: "10%"},
    zIndex: 1,
}),);

const JarkiImage = styled("img")(
    esx({
        position: "absolute",
        width: {xs: "110%", sm: "100%"},
        maxWidth: "1100px",
        bottom: {xs: "-35%", sm: "-28%"},
        zIndex: 1
    }),);

const Waves = styled("img")(
    esx({
        display: {xs: "none", sm: "block"},
        position: "absolute",
        width: "100%",
        top: 0,
        left: 0,
        zIndex: 2
    }),);

const BaronLogo = styled("img")(
    esx({
        maxWidth: {xs: "60px", sm: "151px"},
    }),);

const Header: FC = () => {
    return <Box sx={{height: {xs:"100%",sm:"65vw"}, maxWidth: "100%"}} component={"header"}>
        <Box sx={{
            maxWidth: "100%",
            position: "relative",
        }}>
            <Box sx={{
                // maxHeight: "900px",
                maxWidth: "100%",
                display: "flex",
                justifyContent: "center",
            }}>
                <Waves src={waves} alt={"Waves"}/>
                <NeoTextImage src={neoText} alt={"Neo-nówka"}/>
                <ReactCompareSlider boundsPadding={0} style={{width: '100%', maxHeight: '100%'}} handle={
                    <div style={{backgroundColor: '#1D1D1B', height: '100%', width: '5px', display: 'grid', justifyContent: 'center'}}>
                        <button style={{backgroundImage: `url(${buttons})`, marginTop: '19vw', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', height: '26px', width: '96px', backgroundColor: 'transparent', border: 0}}></button>
                    </div>
                }
                    itemOne={<ReactCompareSliderImage src={neonowkaGold} srcSet={neonowkaGold} alt="Jarki wafel" />}
                    itemTwo={<ReactCompareSliderImage src={neonowka} srcSet={neonowka} alt="Jarki wafel" />}
                />
            </Box>
            {/*<Box sx={{*/}
            {/*    height: "100%",*/}
            {/*    maxHeight: "705px",*/}
            {/*    width: "100%",*/}
            {/*    position: "absolute",*/}
            {/*    left: 0,*/}
            {/*    top: 0,*/}
            {/*    zIndex: -1,*/}
            {/*    backgroundImage: `url(${cityBg})`,*/}
            {/*    backgroundRepeat: "no-repeat",*/}
            {/*    backgroundSize: " 100% auto",*/}
            {/*    backgroundPosition: {xs: "0 -100%", sm: "bottom"},*/}
            {/*}}/>*/}
            <Grid container justifyContent={"center"}
                  sx={{position: "fixed", top: 0, left: 0, width: "100%", padding: "0 24px", zIndex: 100}}>
                <Grid container item justifyContent={"end"} sx={{width: "1025px"}}>
                    <BaronLogo src={baronLogo} alt={"Baron logo"}/>
                </Grid>
            </Grid>
        </Box>
    </Box>
}

export default Header;
